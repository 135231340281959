import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "компонент-show"
    }}>{`Компонент `}<inlineCode parentName="h1">{`<Show>`}</inlineCode></h1>
    <p>{`Компонент `}<inlineCode parentName="p">{`<Show>`}</inlineCode>{` нужен для отображения данных ресурса`}</p>
    <p>{`Для создания записи компонент `}<inlineCode parentName="p">{`<Show>`}</inlineCode>{` вызывает `}<inlineCode parentName="p">{`getOne`}</inlineCode>{` метод из `}<inlineCode parentName="p">{`<dataProvider>`}</inlineCode>{` для получения записи текущего ресурса.`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, (record) => string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Show title={(record) => <Title>Post {record.id}</Title>}>
  <FieldList>
    <TextField source="id" label="ID" />
    <TextField source="title" label="Title" />
    <TextField source="body" label="Body" />
  </FieldList>
</Show>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      